/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import {
  faChevronCircleRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
} from "react";

import { exception } from "../utils/ga";

const STORAGE_KEY = "ls_newsletter_v1";
const INPUT_PLACEHOLDER = "Enter your email here";

const Newsletter = () => {
  const [[placeholder, value, visible, loading, success, error], setState] =
    useState([INPUT_PLACEHOLDER, "", false, false, false, false]);

  useEffect(() => {
    const signedUp = typeof localStorage !== "undefined" &&
      localStorage.getItem(STORAGE_KEY) === "1";

    setState([placeholder, value, !signedUp, loading, success, error]);
  }, [visible]);

  const handleBlur = () => {
    setState([INPUT_PLACEHOLDER, value, visible, loading, success, error]);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState([placeholder, event.target.value, visible, loading, success, error]);
  }

  const handleFocus = () => {
    setState(["", value, visible, loading, success, error]);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

    // IMPORTANT: Prevents the form from submitting itself which would cause an
    // unwanted page refresh.
    event.preventDefault();

    try {

      // Sets the loading indicator.
      setState([placeholder, value, visible, true, false, false]);

      const response = await fetch("https://api.lastingsmiles.net/promo" +
        "?email=" + encodeURIComponent(value) +
        "&campaign=125k");

      // IMPORTANT: The fetch function doesn't throw an exception for a bad
      // request. For example, if the email is empty then a HTTP 400 is returned
      // which has to be handled here.
      if (response.ok) {
        if (typeof localStorage !== "undefined") {
          localStorage.setItem(STORAGE_KEY, "1");
        }

        setState([placeholder, value, visible, false, true, false]);
      } else {

        // Sets the error state.
        setState([placeholder, value, visible, false, false, true]);
      }
    } catch (error) {
      console.error(error);
      exception(error, /*fatal=*/false);

      // Sets the error state.
      setState([placeholder, value, visible, false, false, true]);
    }
  };

  if (visible) {
    return (
      <div className="bg-gray-100 px-2 py-5 text-center">
        <p className="font-medium font-serif text-xl">
          Sign Up and we will automatically donate one Lasting Smiles lip balm to someone in need.
          <br />
          Plus, get 10% off your first order.
        </p>
        {success && (
          <p>
            Thanks for making the world a better place!
            <br />
            Please check your email for the coupon code.
          </p>
        )}
        {!success && (
          <form onSubmit={handleSubmit}>
            <label>
              <span>Email: </span>
              <input
                className={"appearance-none bg-gray-100 border-b-2 m-1 outline-none p-1 rounded-none w-48 " +
                  (error ? "border-red-500" : "border-black")}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                placeholder={placeholder}
                type="email"
                value={value}
              />
            </label>
            <span>&nbsp;</span>
            {loading && (
              <span className="p-2">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  spin={true}
                />
              </span>
            )}
            {!loading && (
              <button
                aria-label="Sign Up"
                className="outline-none p-2"
                type="submit"
              >
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  size="lg"
                />
              </button>
            )}
          </form>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Newsletter;
